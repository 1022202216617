import {Chip} from "@mui/material";
import {Filter} from "@variocube/app-ui";
import {createElement} from "react";
import {useLocalization} from "../../i18n";

export type RecipientsFilter = {
	search?: string;
};

interface RecipientsFilterProps {
	value: RecipientsFilter;
	onChange: (value: RecipientsFilter) => any;
}

export function RecipientsFilter({value, onChange}: Readonly<RecipientsFilterProps>) {
	const {t, s} = useLocalization();

	return (
		<Filter
			label={t("filter.title")}
			labels={s("filter")}
			enableSearch
			onSearch={search => onChange({...value, search})}
			active={[
				value.search && (
					<Chip
						key={"search"}
						label={`${t("filter.search")}: ${value.search}`}
						onDelete={() => onChange({...value, search: undefined})}
					/>
				),
			]}
		/>
	);
}
