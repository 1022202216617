import {Box, Button, Grid, Paper, TableCell, TableRow, Typography} from "@mui/material";
import {Breadcrumbs, ColumnType, ContainerLayout, ContentTable, PagingSettings, useStorage} from "@variocube/app-ui";
import {createElement, Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {useAsync, useAsyncCallback} from "react-async-hook";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";
import {Recipient} from "../../domain/Delivery";
import {RecipientsProvider} from "../../domain/RecipientsProvider";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {BreadcrumbRouterLink} from "../BreadcrumbRouterLink";
import {CubeNameDisplay} from "../CubeNameDisplay";
import {DateComponent} from "../DateComponent";
import {HelmetTitleWrapper} from "../HelmetTitleWrapper";
import {Loading} from "../Loading";
import {recipientsPaging} from "../pagings";
import {useTenant, useTenantId, useTenantUserRole} from "../TenantContextProvider";
import {recipientsColumns} from "../uis";
import {RecipientsFilter} from "./RecipientsFilter";

export function RecipientsList() {
	const {t, language} = useLocalization();
	const tenantId = useTenantId();
	const tenant = useTenant();
	const {isAdmin, isAdminLight} = useTenantUserRole();

	const baseColumns: ColumnType = useMemo<ColumnType>(() => ({
		"recipientName": {show: true, name: t("recipients.name")},
		"email": {show: true, name: t("recipients.email")},
		"phone": {show: true, name: t("recipients.phone")},
		"cubeId": {show: true, name: t("recipients.cubeId")},
		"department": {show: true, name: t("recipients.department")},
		"building": {show: true, name: t("recipients.building")},
		"pickupKey": {show: false, name: t("recipients.pickupKey")},
		"created": {show: true, name: t("recipients.created")},
		"employeeNumber": {show: false, name: t("recipients.employeeNumber")},
	}), [t, language]);

	const [columns, setColumns] = useState<ColumnType>();
	const [filter, setFilter] = useStorage<RecipientsFilter>("RecipientsList.filter", {});
	const [pageable, setPageable] = useState<PagingSettings<keyof typeof baseColumns>>(recipientsPaging.getSettings());

	const {loading: reImportLoading, execute: executeReImport} = useAsyncCallback(
		async () => {
			await RecipientsProvider.reImport(tenantId);
			await executeSearch(tenantId, filter, pageable);
		},
	);

	const {loading: searchLoading, result: searchResult, execute: executeSearch} = useAsync(
		async () => {
			recipientsPaging.updateSettings(pageable);
			return await RecipientsProvider.search(tenantId, filter, recipientsPaging);
		},
		[
			tenantId,
			filter,
			pageable,
		],
	);

	const handleColumnsChange = useCallback((columnType: ColumnType) => {
		if (tenant && !tenant.recipientPickupKeyExposed) {
			columnType["pickupKey"].show = false;
		}
		setColumns(columnType);
		recipientsColumns.set(columnType);
	}, [tenant]);

	const loading = searchLoading || reImportLoading;

	// Reset columns on tenant change
	useEffect(() => {
		let columns = recipientsColumns.get();
		if (columns == null) {
			recipientsColumns.set(baseColumns);
			columns = baseColumns;
		}
		if (tenant && !tenant.recipientPickupKeyExposed) {
			columns["pickupKey"].show = false;
		}
		setColumns(columns);
	}, [tenant]);

	// Reset column language on language change
	useEffect(() => {
		if (columns) {
			const updatedColumns = Object.keys(columns).reduce((acc, key) => {
				acc[key] = {
					...columns[key],
					name: baseColumns[key].name,
				};
				return acc;
			}, {} as ColumnType);

			setColumns(updatedColumns);
		}
	}, [language]);

	return (
		<ContainerLayout>
			<HelmetTitleWrapper pageTitle={t("recipients.plural")} />
			<Grid container spacing={gs}>
				<Grid item xs={12}>
					<Breadcrumbs>
						<BreadcrumbRouterLink to={`/${tenantId}/recipients`}>
							{t("recipients.plural")}
						</BreadcrumbRouterLink>
					</Breadcrumbs>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={gs}>
						<Grid item style={{flexGrow: 1}}>
							<Typography variant="h2">{t("recipients.plural")}</Typography>
						</Grid>
						{(isAdmin || isAdminLight)
							&& (
								<Grid item>
									{isAdmin && (
										<Button
											variant="outlined"
											disabled={loading}
											onClick={() => executeReImport()}
										>
											{t("recipients.actions.reImport")}
										</Button>
									)}
									<Box component="span" marginX={1}></Box>
									<Button
										variant="contained"
										disabled={loading}
										component={Link}
										to={`/${tenant.centerId}/recipients/create`}
									>
										{t("recipients.actions.create")}
									</Button>
								</Grid>
							)}
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<RecipientsFilter value={filter} onChange={setFilter} />
				</Grid>
				<Grid item xs={12}>
					<Paper>
						{!searchResult && <Loading />}
						{(searchResult && columns && pageable)
							&& (
								<ContentTable
									pageable={pageable}
									page={searchResult}
									inProgress={loading}
									columns={columns}
									onPageableChange={setPageable}
									onColumnsChange={handleColumnsChange}
									renderTableBody={
										<Fragment>
											{searchResult.content.map((recipient) => (
												<RecipientRow
													key={recipient.id}
													recipient={recipient}
													columns={columns}
												/>
											))}
										</Fragment>
									}
								/>
							)}
					</Paper>
				</Grid>
			</Grid>
		</ContainerLayout>
	);
}

interface RecipientRowProps {
	recipient: Recipient;
	columns: ColumnType;
}

function RecipientRow(props: Readonly<RecipientRowProps>) {
	const {recipient, columns} = props;
	const navigate = useNavigate();
	const tenantId = useTenantId();

	const isColumnActive = useCallback((name: string) => (columns[name] && columns[name].show), [columns]);

	const handleClick = (recipient: Recipient) => {
		if (recipient.id) {
			navigate(`/${tenantId}/recipients/${recipient.id}`);
		}
	};

	return (
		<TableRow hover onClick={() => handleClick(recipient)}>
			{isColumnActive("recipientName") && <TableCell>{recipient.recipientName}</TableCell>}
			{isColumnActive("email") && <TableCell>{recipient.email}</TableCell>}
			{isColumnActive("phone") && <TableCell>{recipient.phone}</TableCell>}
			{isColumnActive("cubeId") && (
				<TableCell>{recipient.cubeId && <CubeNameDisplay cubeId={recipient.cubeId} />}</TableCell>
			)}
			{isColumnActive("department") && <TableCell>{recipient.department}</TableCell>}
			{isColumnActive("building") && <TableCell>{recipient.building}</TableCell>}
			{isColumnActive("pickupKey") && <TableCell>{recipient.pickupKey ?? "--"}</TableCell>}
			{isColumnActive("created") && (
				<TableCell>{recipient.created ? <DateComponent date={recipient.created} /> : "--"}</TableCell>
			)}
			{isColumnActive("employeeNumber") && <TableCell>{recipient.employeeNumber}</TableCell>}
		</TableRow>
	);
}
